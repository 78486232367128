<template>
  <y_layout main-body-style="padding:0">
    <div class="flex-def flex-cCenter y-top-nav-box">
      <div class="y-top-nav-item y-pointer" v-for="(v,k) in menu" :key="k"
           :class="$route.name.indexOf(v.path) !== -1 ? 'active' : ''"
           @click="$router.push({name:v.path,params:$route.params})"
      >{{ v.title }}</div>
    </div>
    <div style="padding: 1.25rem;">
      <router-view/>
    </div>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";

export default {
  name: "index",
  components: {y_layout},
  data() {
    return {
      menu: [
        {title: "红包列表", path: "voice-red-packet-list"},
        {title: "聚合页DIY", path: "voice-red-packet-design"},
        {title: "基础配置", path: "voice-red-packet-setting"},
      ],
    }
  },
}
</script>

<style scoped>
.y-top-nav-box {
  background-color: #FFFFFF;
  border-bottom: 1px solid #ededed;
  padding: .5rem 2rem;
  height: 40px;
}

.y-top-nav-item {
  font-size: 1rem;
  color: #888888;
  font-weight: 600;
  padding: .5rem 1rem;
  border-radius: 2rem;
}
.y-top-nav-item+.y-top-nav-item{
  margin-left: 2rem;
}

.y-top-nav-item.active {
  color: #5D60F6;
  background-color: #e9e9fa;
}

.y-pointer:hover{
  color: #5D60F6;
}
</style>